import { mapGetters, mapMutations, mapActions } from 'vuex'
import { doQuery, doAction,doBaidu } from "@/api/core";
import { searchByMulti } from "@/api/lesson";
import CmyPage from '@/components/cmy-page/cmy-page.vue'
import ErrorInfoDrawerFaq from '@/components/error-info-drawer/error-info-drawer-faq'
import util from '@/utils/cmyUtils.js'

export default {
    name: "knowledge",
    data() {
        return {
            keywords: undefined,

            MAX_SEARCH_PAGES: 50,

            browseMode: '0',

            //new：知识目录
            catalog_data: [],
            catalog: [],
            catalog_spinning: false,
            catalog_selected:'0',
            catalog_move_selected:'',

            //排序条件
            sorttypeArray: ["按相关程度排序", "按发布时间排序"],
            sorttypeField: ['', 'create_time'],
            sorttypeIndex: 0,

            //类型与子类型，以及当前选中的选项卡
            ktypeList: [],
            ksubtypeList: [],
            ktypeSelectedId: '',
            ksubtypeSelectedId: '',

            //推荐阅读
            recommendList: [],

            searchList: [],
            searchCount: 0,
            searchLogs: [],

            pagination: {
                page: 1,
                limit: 10,
                end: false
            },

            knowledges: {},

            knowledgeModalVisible: false,
            knowledgeDetail: null,

            hide: null,

            kidSelect: null,

            modalStyle:{
                'padding':'10px',
                'height': (this.$store.getters.winSize[1] - 300) + 'px',
                'background-color': 'rgb(255, 255, 255)',
                'overflow-y': 'auto',
                'overflow-x': 'visible'
            },
            modalWidth:'1000px',
            modalIcon:'fullscreen',
        }
    },

    components: {
        CmyPage,
        ErrorInfoDrawerFaq
    },

    computed: {
        ...mapGetters([
            'mobilephone',
            'getPkdbtypes',
            'getKdbtypes',
            'staffId',
            'winSize'
        ])
    },

    mounted() {

        this.hide = this.$message.loading('正在加载中...', 0)

        this.searchLogs = localStorage.getItem(this.$consts.storageKey.XST_KNOWLEDGE_SEARCH_LOG_.concat(this.mobilephone)) ? JSON.parse(localStorage.getItem(this.$consts.storageKey.XST_KNOWLEDGE_SEARCH_LOG_.concat(this.mobilephone))) : []
/*
        this.RefreshPkdbtypes()
            .then(() => {
                var typelist = []
                this.getPkdbtypes.forEach((item, index) => {
                    typelist.push({
                        id: item.pkdbtype_code,
                        title: item.pkdbtype
                    })
                    this.pagination.page = 1
                })

                this.ktypeList = typelist
                this.ksubtypeList = []

                this.RefreshKdbtypes()
                    .then(() => {
                        this.handleTabChange(this.ktypeList[0].id);
                    })
            })
*/

        //查询目录数据
        this.getCatalogData();
        this.refreshKnowledges();

    },
    methods: {

        onSelectNode(selectedKeys, e) {

            const that = this
            that.catalog_selected = selectedKeys[0];

            if (that.browseMode == '0'){
                that.pagination.page = 1
                that.refreshKnowledges();
            }

            if (that.browseMode == '9'){
                that.startSearch(1)
            }
        },

        onFullScreen(e){

            if (this.modalWidth == '1000px') {
                this.$set(this.modalStyle, "height", this.$store.getters.winSize[1] + "px");
                this.modalWidth = '100%';
                this.modalIcon = 'fullscreen-exit';
            }

            else {
                this.$set(this.modalStyle, "height", (this.$store.getters.winSize[1] - 300) + "px");
                this.modalWidth = '1000px';
                this.modalIcon = 'fullscreen';
            }

        },

        ...mapActions([
            'RefreshPkdbtypes',
            'RefreshKdbtypes',
            'GetKdbtypesByPkdbtypeCode',
            'GetKdbtypeCodesByPkdbtypeCode',
            'GetPkdbtypeByKdbtypeCode',
            'GetTxttypeByKdbtypeCode'
        ]),

        handleTabChange(pkdbtypeCode) {
            const that = this
            //新的选择选项卡
            that.ktypeSelectedId = pkdbtypeCode
            that.ksubtypeSelectedId = ''

            //获得子类型列表
            that.ksubtypeList = [];

            that.GetKdbtypesByPkdbtypeCode(pkdbtypeCode)
                .then(res => {
                    res.forEach(function(item, index) {
                        that.ksubtypeList.push({
                            id: item.kdbtype_code,
                            title: item.kdbtype
                        })
                    })
                    that.refreshKnowledges(1)
                })
        },

        getCatalogData(){
            const that = this
            that.catalog_spinning = true
            var params = {
                query_code:'LNH50P',
                page:1,
                limit:1
            }
            doQuery(params).then(res => {
                if (res.errcode == that.$consts.biz.OK) {
                    that.catalog_data = res.data
                    that.catalog = util.getTreeData(res.data,null,'folder-open')
                } else {
                    that.$message.error(res.errmsg)
                }
            }).finally(() => {
                that.catalog_spinning = false
            })
        },

        refreshKnowledges(page = this.pagination.page) {
            const that = this
            doQuery({
                query_code: 'LNH50Y',
                param_str2: that.catalog_selected,
                page: page,
                limit: that.pagination.limit
            }).then(res => {

                if(res.errcode >= that.$consts.biz.OK && res.data){
                    var lastLaunchTime = localStorage.getItem(that.$consts.storageKey.LAST_LAUNCH_TIME_.concat(that.mobilephone))

                    /*时间做处理*/
                    for (var i in res.data) {
                        if (lastLaunchTime && lastLaunchTime != '' && res.data[i].basis_release > lastLaunchTime) {
                            res.data[i].newtag = '1'
                        }else {
                            res.data[i].newtag = '0'
                        }
                        res.data[i].basis_release = res.data[i].basis_release.substr(0, 10)
                        that.$set(that.knowledges, that.catalog_selected, res.data)

                    }

                    that.pagination.page = page
                    if(res.data.length < that.pagination.limit) {
                        that.pagination.end = true
                    }else {
                        that.pagination.end = false
                    }
                }
            }).finally(() => {
                setTimeout(that.hide(), 1000)
            })
        },

        handleKsubTypeMenuClick({ item, key, keyPath }) {
            const that = this
            if(that.browseMode == '0') {
                if (key != that.ksubtypeSelectedId){
                    that.ksubtypeSelectedId = key
                    that.refreshKnowledges(1)
                }else {
                    that.ksubtypeSelectedId = ''
                    that.refreshKnowledges(1);
                }
            }else if(that.browseMode == '9') {
                if (key != that.ksubtypeSelectedId){
                    that.ksubtypeSelectedId = key
                    that.pagination.end = true
                    this.searchList = []
                    this.startSearch(1);
                }else {
                    that.ksubtypeSelectedId = '';
                    that.pagination.end = true
                    this.searchList = []
                    this.startSearch(1);
                }
            }
        },

        startSearch(page = this.pagination.page) {
            const that = this

            //普通搜索关键词不能为空
            if (that.keywords == null || that.keywords == '') {
                that.$message.error('搜索关键词不能为空')
                return
            }

            var params = {
                url: util.buildUnitPath('lucene/kdb-index/search'),
                matchType:'2',  //暂时固定
                catalogCodeArr:[], //暂时固定
                keywords:that.keywords,
                summaryLength:500,
                page: page || 1,
                limit: 10
            }

            //对目录code做特殊处理，加上子目录
            if (that.catalog_selected == '0'){
                that.catalog_data.forEach(item=>params.catalogCodeArr.push(item.id))
            }
            else {
                that.catalog_data.forEach(item=>{

                    if(item.id.indexOf(that.catalog_selected) == 0)
                        params.catalogCodeArr.push(item.id)

                })
            }

            //开始搜索
            doBaidu(params).then(res => {
                if (res.errcode == that.$consts.biz.OK) {

                    that.pagination.page = page

                    var doc = res.data.searchList //如果没有搜索到结果
                    that.searchCount = res.data.totalCount
                    that.browseMode = '9'

                    if(doc.length < that.pagination.limit) {
                        that.pagination.end = true
                    }else {
                        that.pagination.end = false
                    }

                    if (!doc || doc.length == 0) {
                        that.searchList = []
                    }else {
                        doc.forEach(function(item, index) {
                            that.setSearchItem(item)
                        })
                        that.searchList = doc
                    }
                    
                } else {
                    that.$message.error('查询失败')
                }
            }).finally(() => {

            })


            /*
            var kdbtypeCodeArr = []
            if(that.ksubtypeSelectedId && that.ksubtypeSelectedId != '' && that.ksubtypeSelectedId != '%') {
                kdbtypeCodeArr = [that.ksubtypeSelectedId]
                that.doSearchByMulti(kdbtypeCodeArr,page)
            }else {
                that.GetKdbtypeCodesByPkdbtypeCode('3')
                    .then(res => {
                        kdbtypeCodeArr = res
                        that.doSearchByMulti(kdbtypeCodeArr,page)
                    })
            }
            */

        },

        doSearchByMulti(kdbtypeCodeArr = [],page = this.pagination.page){
            const that = this
            searchByMulti({
                keywords: that.$cmyUtils.clearTxt(that.keywords),
                matchType: '2',
                basis: '',
                endTime: '',
                isInvalid: '',
                kdbtypeCodeArr: kdbtypeCodeArr,
                refSrc: '',
                startTime: '',
                sortField:that.sorttypeField[that.sorttypeIndex],
                page: page,
                limit: that.pagination.limit
            }).then(res => {
                if(res.errcode != '0' || !res.data) {
                    that.$message.error('搜索发生异常，请稍后再试')
                    that.searchList = []
                    that.pagination.end = true
                    return
                }
                that.pagination.page = page

                var doc = res.data.searchList //如果没有搜索到结果
                that.searchCount = res.data.totalCount
                that.browseMode = '9'


                if(doc.length < that.pagination.limit) {
                    that.pagination.end = true
                }else {
                    that.pagination.end = false
                }

                if (!doc || doc.length == 0) {
                    that.searchList = []
                }else {
                    doc.forEach(function(item, index) {
                        that.setSearchItem(item)
                    })
                    that.searchList = doc
                }
            })
        },
        /**
         * 对搜索结果的item进行特别处理
         */
        setSearchItem: function(item) {
            var that = this

            //结果加红标记处理
            if (item.question) {
                item.question = item.question.replace(/<font color='red'>/g, '<a style="color:red;">')
                item.question = item.question.replace(/<\/font>/g, '</a>')
            }
            if (item.answer) {
                item.answer = item.answer.replace(/<font color='red'>/g, '<a style="color:red;">')
                item.answer = item.answer.replace(/<\/font>/g, '</a>')
                item.answer = '...' + item.answer + '...'
            }
        },

        onSearchLogsMenuClick({ key }) {
            const that = this
            if(!key) {
                that.browseMode = '0'
                return
            }
            that.keywords = key
            var searchLogs = [...that.searchLogs]
            var arr = []
            var index = null
            for(var i = 0; i < searchLogs.length; i++) {
                if(key == searchLogs[i]) {
                    index = i
                    break
                }
            }
            if(index != null) {
                searchLogs.splice(index, 1);
                arr = [key,...searchLogs]
                that.searchLogs = arr
                localStorage.setItem(that.$consts.storageKey.XST_KNOWLEDGE_SEARCH_LOG_.concat(that.mobilephone),JSON.stringify(arr))
                that.startSearch(1)
            }
        },

        onSearch(e) {
            const that = this

            if(e.type == 'keydown' && e.keyCode != 13) {
                return
            }

            var keywords = that.$cmyUtils.trim(that.keywords)

            if(!keywords) {
                that.browseMode = '0'
                that.refreshKnowledges(1)
                return
            }
            var searchLogs = [...that.searchLogs]
            var arr = []
            var index = null
            for(var i = 0; i < searchLogs.length; i++) {
                if(keywords == searchLogs[i]) {
                    index = i
                    break
                }
            }
            if(index != null) {
                searchLogs.splice(index, 1);
                arr = [keywords,...searchLogs]
            }else {
                if(searchLogs.length < 10) {
                    arr = [keywords,...searchLogs]
                }else {
                    arr = [keywords,...searchLogs]
                    arr.shift();
                }
            }
            that.searchLogs = arr
            localStorage.setItem(that.$consts.storageKey.XST_KNOWLEDGE_SEARCH_LOG_.concat(that.mobilephone),JSON.stringify(arr))
            that.startSearch(1)
        },
        onSortTypeMenuClick({ key }) {
            const that = this
            that.sorttypeIndex = key
            that.startSearch(1)
        },

        showKnowledgeModal(id) {
            const that = this
            doQuery({
                query_code: 'LNH50Z',
                param_str1: id,
                page: 1,
                limit: 1
            }).then(res => {
                if (res.errcode >= that.$consts.biz.OK && res.data && res.data.length > 0) {

                    var resData = res.data[0]

                    //对其中的知识链接处理content
                    resData.content = (resData.content == null ? '' : resData.content)
                    resData.content = resData.content.replace(/<kdetail kid="/g,'<span')
                    resData.content = resData.content.replace(/<\/kdetail>/g, '<\/span>')
                    if (resData.txttype && resData.txttype.indexOf('法规') >= 0 && resData.content.indexOf('财猫云') < 0) {
                        resData.content = resData.content.replace(/color: rgb\(255, 0, 0\)/g,'font-weight:bold')
                        resData.content = resData.content.replace(/color:rgb\(255, 0, 0\)/g,'font-weight:bold')
                        resData.content = resData.content.replace(/color:\#ff0000/g,'font-weight:bold')
                        resData.content = resData.content.replace(/color: \#ff0000/g,'font-weight:bold')
                        resData.content = resData.content.replace(/color:\#FF0000/g,'font-weight:bold')
                        resData.content = resData.content.replace(/color: \#FF0000/g,'font-weight:bold')
                        resData.content = resData.content.replace(/color: rgb\(0, 0, 255\)/g,'font-weight:bold')
                        resData.content = resData.content.replace(/color:rgb\(0, 0, 255\)/g,'font-weight:bold')
                        resData.content = resData.content.replace(/color:\#0000ff/g,'font-weight:bold')
                        resData.content = resData.content.replace(/color: \#0000ff/g,'font-weight:bold')
                        resData.content = resData.content.replace(/color:\#0000FF/g,'font-weight:bold')
                        resData.content = resData.content.replace(/color: \#0000FF/g,'font-weight:bold')
                    }

                    resData.kid = id
                    that.knowledgeDetail = resData
                    that.knowledgeModalVisible = true
                }else {
                    that.$message.error('查询知识详情失败或该知识不存在')
                }
            })
        },
        handleKnowledgeModalOk() {
            const that = this

        },
        showSubmitErrorModal() {
            const that = this
            that.kidSelect = that.knowledgeDetail.kid
        },

        onKnowledgeModalClose() {
            const that = this
            that.knowledgeModalVisible = false
            that.kidSelect = null
            that.$refs['errorInfoDrawer'].clearErrorInfo()
        },

    }
}
