<template>
    <a-drawer
            title="编辑纠错信息"
            placement="right"
            :closable="true"
            :mask="false"
            :visible="kid != null"
            :width="450"
            @close="onSubmitErrorModalClose()"
    >
        <a-row>
            <div v-for="(item, index) in errorTags" :class="index == 0 ? 'error-tag': 'error-tag huiwen-margin-left-5'" :style="{'background-color': errorTagsSelect.includes(item.tag_code) ?  '#1890ff' : '#999'}" @click="setErrorTagsSelect(item.tag_code)">{{ item.tag }}</div>
        </a-row>
        <a-row class="huiwen-margin-top-20">
            <a-textarea placeholder="请在此输入您的详细纠错说明" type="textarea" allowClear :autoSize="{ minRows: 5, maxRows: 10}" v-model="errorInfo"/>
        </a-row>
        <a-row class="huiwen-margin-top-20">
            <a-button class="huiwen-right huiwen-margin-left-10" type="primary" @click="submitErrorInfo()">立即提交</a-button>
            <a-button class="huiwen-right" type="default" @click="clearErrorInfo()">重置</a-button>
        </a-row>
    </a-drawer>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { doQuery, doAction } from "@/api/core"

    export default {
        name: "error-info-drawer-faq",
        data () {
            return {
                errorInfo: '',
                errorTags: [],
                errorTagsSelect: [],
            }
        },
        props: {
            kid: String
        },
        watch: {
          'kid': function (newVal,oldVal) {
              if(newVal) {
                  this.clearErrorInfo()
              }
          }
        },
        computed: {
            ...mapGetters([
                'staffId'
            ])
        },
        mounted() {
            this.queryErrorTags()
        },
        methods: {
            queryErrorTags() {
                const that = this
                doQuery({
                    query_code: 'LNFP00',
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK && res.data) {
                        that.errorTags = res.data
                    }
                })
            },
            onSubmitErrorModalClose() {
                const that = this
                that.errorInfo = ''
                that.errorTagsSelect = []
                that.$emit('close')
            },
            setErrorTagsSelect(tagCode) {
                const that = this
                if(that.errorTagsSelect.includes(tagCode)) {
                    that.errorTagsSelect.splice(that.errorTagsSelect.indexOf(tagCode),1)
                }else {
                    that.errorTagsSelect.push(tagCode)
                }
            },
            submitErrorInfo() {
                const that = this
                var kid = that.kid
                var errorTagsSelectStr = that.errorTagsSelect.sort().join('|')
                if(that.errorTagsSelect.length == 0) {
                    that.$message.error('请至少选择一个标签')
                    return
                }
                doAction({
                    action_code: 'LNFD2',
                    param_str3: kid,
                    param_str1: that.staffId,
                    param_str2: errorTagsSelectStr,
                    param_str5: that.errorInfo
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK ) {
                        if(res.data.errcode >= that.$consts.biz.OK) {
                            that.$message.success(that.$consts.biz.OK_MSG)
                            that.onSubmitErrorModalClose()
                        }else {
                            that.$message.error(that.$consts.biz.FAIL_MSG)
                        }
                    }else {
                        that.$message.error(that.$consts.biz.FAIL_MSG)
                    }
                })
            },
            clearErrorInfo() {
                const that = this
                that.errorInfo = ''
                that.errorTagsSelect = []
            },
        }
    }
</script>

<style scoped>

    .error-tag {
        float: left;
        padding: 2px 8px;
        border-radius: 3px;
        font-size: 13px;
        background-color: #999;
        color: #ffffff;
        cursor: pointer;
    }

</style>
